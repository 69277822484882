<template>
  <ul class="clebex-item-section company-preference-calendar">
    <li class="clebex-item-section-item full-right-underline">
      <div class="clebex-item-content-wrapper">
        <div class="clebex-section-input">
          <p class="text-label ellipsis">
            <span> {{ selectedHours }}:{{ selectedMinutes }}</span>
          </p>
        </div>
      </div>
    </li>
    <li class="clebex-item-section-item full-right-underline">
      <div class="clebex-item-content-wrapper">
        <section class="clebex-pill-menu-tabs">
          <ul class="clebex-pill-tabs">
            <li class="clebex-pill-tabs-content visible">
              <div class="select-time-picker visible">
                <ul
                  class="list-of-time-slots list-of-hours"
                  v-if="!showMinutes"
                >
                  <li
                    v-for="(hour, index) in hours"
                    :key="index"
                    class="select-time-button-wrapper"
                  >
                    <button
                      data-skip
                      class="select-time-btn"
                      type="button"
                      @click="setData(hour, false)"
                    >
                      {{ hour }}:00
                    </button>
                  </li>
                </ul>
                <ul class="list-of-time-slots list-of-hours" v-if="showMinutes">
                  <li
                    v-for="(minute, index) in minutes"
                    :key="index"
                    class="select-time-button-wrapper"
                  >
                    <button
                      data-skip
                      class="select-time-btn"
                      type="button"
                      @click="setData(false, minute)"
                    >
                      {{ selectedHours }}:{{ minute }}
                    </button>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </section>
      </div>
    </li>
  </ul>
  <router-view></router-view>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { apiEndpoints } from "@/services/constants";
import httpServiceAuth from "@/services/http-service";
import { errorHandler } from "@/services/error-handler";

export default {
  name: "EveningFrom",
  data() {
    return {
      hours: Array.from({ length: 24 }, (v, i) => (i < 10 ? "0" + i : "" + i)),
      minutes: Array.from({ length: 12 }, (v, i) =>
        i * 5 < 10 ? "0" + i * 5 : "" + i * 5
      ),
      selectedHours: null,
      selectedMinutes: null,
      showMinutes: false
    };
  },
  mounted() {
    this.setInitialData();
  },
  computed: {
    ...mapState("companyPreferences", ["preferences", "preferenceUpdateData"])
  },
  props: {
    preferenceId: {
      type: Number,
      required: true
    }
  },
  methods: {
    ...mapActions("companyPreferences", [
      "setPreferenceUpdateData",
      "setPreferences"
    ]),
    setInitialData() {
      this.showMinutes = false;
      const initialValue = this.preferenceUpdateData.values[0].value.split(":");
      this.selectedHours = initialValue[0];
      this.selectedMinutes = initialValue[1];
    },
    setData(hours, minutes) {
      if (!minutes) {
        this.selectedHours = hours;
        this.showMinutes = true;
        return;
      } else {
        this.$store.commit("loader/setScreenLoading", true, { root: true });
        this.selectedMinutes = minutes;
        const newPreferenceUpdateData = {
          values: [
            {
              value: `${this.selectedHours}:${this.selectedMinutes}`
            }
          ]
        };
        if (this.preferenceUpdateData.values[0].id) {
          newPreferenceUpdateData.values[0].id = this.preferenceUpdateData.values[0].id;
        }
        this.setPreferenceUpdateData(newPreferenceUpdateData);
        httpServiceAuth
          .put(
            `${apiEndpoints.company.companyPreferences}/${this.preferenceId}`,
            this.preferenceUpdateData
          )
          .then(response => {
            const preferencesUpdateData = [...this.preferences];
            const objIndex = preferencesUpdateData.findIndex(
              obj => obj.id == response.data.data.id
            );
            preferencesUpdateData[objIndex] = response.data.data;
            this.setPreferences(preferencesUpdateData);
            this.showMinutes = false;
            this.$store.commit("loader/setScreenLoading", false, {
              root: true
            });
            this.$router.push({ name: "r_company-preference-category" });
          })
          .catch(error => {
            if (error.response) {
              errorHandler(error.response, this.findElement());
            }
          });
      }
    }
  }
};
</script>
